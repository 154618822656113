import Vue from 'vue'
import * as t from '../mutations'
import http from '@/http'
import axiosClient from '@/http'

import { debounce } from 'lodash/function'
import { SERVICE_TYPES, OPERATING_STATUSES, DOMESTIC } from '@/common/modules/constants'
import { Client, GET } from '@/api/client'
import { FILING_BY_COMPANY_AND_JURISDICTIONS } from '@/api/v3/endpoints'

import {
  state as BaseState,
  getters as BaseGetters,
  actions as BaseActions,
  mutations as BaseMutations,
} from '@/store/base'

const STATE = {
  ...BaseState,
  namespace: 'companies',
  activeServices: [],
  suspendedServices: [],
  activeOrderItems: [],
  activeSubscriptions: [],
  activeProductBundles: [],
  currentCompanyId: null,
  companies: {},
  companiesLoaded: false,
  resultsPerPage: 500,
  error: null,
  servicesError: null,
  registrations: [],
}

const SERVICE_TYPES_VALUES = Object.values(SERVICE_TYPES)
const OPERATING_STATUSES_VALUES = Object.values(OPERATING_STATUSES)

const GETTERS = {
  ...BaseGetters,
  currentCompany:                   state => state.companies[state.currentCompanyId],
  currentCompanyId:                 state => state.currentCompanyId,
  activeServices:                   state => state.activeServices,
  suspendedServices:                state => state.suspendedServices,
  activeOrderItems:                 state => state.activeOrderItems,
  activeProductBundles:             state => state.activeProductBundles,
  registrations:                    state => state.registrations,
  activeServicesByType: (state, getters) => type => {
    return getters.activeServices.filter(service => service.type === type)
  },
  activeServicesByTypeAndJurisdiction: (state, getters) => (type, jurisdiction) =>  {
    // Leave == as is until plenty of time to debug making it === because we want undefined to match with null
    return getters.activeServicesByType(type).filter(service => service.jurisdiction?.id == jurisdiction?.id)
  },
  activeOrderItemsByCategory: (_state, getters) => category => {
    return getters.activeOrderItems.filter(activeOrderItem => {
      return activeOrderItem?.product?.filing_name === category
    })
  },
  activeServicesByCategory: (state, getters, _rootState, rootGetters) => category => {
    return getters.activeServices
      .filter(service => {
        service = service?.product || service
        return rootGetters['checkout/deduceProductCategory'](service) === category
      })
  },
  activeServicesByCategoryAndJurisdiction: (state, getters, _rootState, rootGetters) => (category, jurisdiction) => {
    return getters.activeServicesByCategory(category)
      // Need == to compare undefined with null
      .filter(service => rootGetters['checkout/deduceProductJurisdiction'](service)?.id == jurisdiction?.id )
  },
  activeOrderItemsByJurisdiction: (state, getters) => (jurisdiction) => {
    return getters.activeOrderItems.filter(oi => oi.jurisdiction === jurisdiction.state_province_region)
  },
  activeOrderItemsByJurisdictions: (state, getters) => (jurisdictions) => {
    return getters.activeOrderItems.filter(oi => jurisdictions.includes(oi.jurisdiction))
  },
  activeOrderItemsByCategoryAndJurisdiction: (_state, getters, _rootState, rootGetters) => (category, jurisdiction) => {
    return getters.activeOrderItems.filter(activeOrderItem => {
      const aoiCat = rootGetters['checkout/deduceProductCategory'](activeOrderItem?.product)
      const aoiJur = rootGetters['checkout/deduceProductJurisdiction'](activeOrderItem?.product)?.id
      // Need == to compare undefined with null
      return aoiCat == category
        && (aoiJur == null
          || aoiJur == jurisdiction?.id)
    })
  },
  activeRAServices: (state, getters) => getters.activeServicesByType('registered-agent'),
  activeMFServices: (state, getters) => getters.activeServicesByType('mail-forwarding'),
  activeComplianceServices: (state, getters) => getters.activeServicesByType('compliance'),
  activeComplianceServiceInJurisdiction: (state, getters) => (state_province_region) => {
    return getters.activeComplianceServices.some(cs => cs.jurisdiction?.state_province_region === state_province_region)
  },
  activeSubscriptions: state => state.activeSubscriptions,
  activeSubscriptionsByJurisdiction: (state, getters) => (jurisdiction) => {
    // Need == to compare undefined with null
    return getters.activeSubscriptions.filter(service => service.subscription.jurisdiction_id == jurisdiction?.id)
  },
  companies: state => Object.values(state.companies),
  companiesWithDistinctServiceType: (_state, getters) => (serviceType) => {
    return getters.companies.filter(c => c.distinct_service_type.includes(serviceType))
  },
  find: state => id => state.companies[id],
  companiesLoaded: state => state.companiesLoaded,
  count: (_, getters) => getters.companies.length,
  formationFiling: state => {
    return state.activeOrderItems.find(oi => oi.product?.filing_name?.includes('form a company'))
  },
  formACompanyFiling: state => {
    return state.activeOrderItems.find(oi => oi.product?.filing_name === 'form a company')
  },
  freeFormACompanyFiling: state => {
    return state.activeOrderItems.find(oi => oi.product?.filing_name === 'free form a company')
  },
  activeBoiFiling: state => {
    return state.activeOrderItems.find(oi => oi.product?.filing_name === 'beneficial ownership information report')
  },
  vehicleFormACompanyFiling: state => {
    return state.activeOrderItems.find(oi => oi.product?.filing_name === 'vehicle form a company')
  },
  completedFormACompanyFiling: state => {
    return state.activeOrderItems.find(oi => oi.product?.filing_name === 'form a company' && oi.status === 'completed')
  },
  simpleProductOperatingAgreement: state => {
    return state.activeOrderItems.find(oi => oi.type === 'internal-use' && oi.product?.name === 'Operating Agreement')
  },
  einFiling: state => {
    return state.activeOrderItems.find(oi => oi.product?.filing_name === 'tax id')
  },
  activeOrderItemsByFilingName: (state, getters) => (filingName) => {
    return getters.activeOrderItems.some(oi => oi.product?.filing_name === filingName)
  },
  activeOrderItemsByProductName: state => (productName) => {
    return state.activeOrderItems.find(oi => oi.product?.name === productName)
  },
  activeFilingByFilingName: (state, getters) => (filingName) => {
    return getters.activeOrderItems.find(oi => oi.product?.filing_name === filingName)
  },
  resultsPerPage: state => state.resultsPerPage,
  error: state => state.error,
  servicesError: state => state.servicesError,
  hasExistingRAServiceInJurisdiction: (state, getters) => (jurisdiction) => {
    const stateProvinceRegion = jurisdiction?.state_province_region || jurisdiction
    return getters.activeRAServices.find(service =>
      service.product.description.includes(stateProvinceRegion)
    )
  },
  hasActiveFreeMailForwarding: () => products => {
    const isFreeMailForwarding = mainProduct => mainProduct?.product?.subcategory?.toLowerCase().includes('free_limited_use')

    return products.some(isFreeMailForwarding)
  },
  productsOfTypeWithCompanyOrInCart: (state, getters, _rootState, rootGetters) => (type, jurisdiction) => {
    const activeServices   = getters.activeServicesByTypeAndJurisdiction(type, jurisdiction)
    const cartItems        = rootGetters['checkout/cartItemsByCategoryAndJurisdiction'](type, jurisdiction)
    const cartBundleItems  = rootGetters['checkout/bundleCartItemsByCategoryAndJurisdiction'](type, jurisdiction)
    const activeOrderItems = getters.activeOrderItemsByJurisdiction(jurisdiction).filter(oi => oi.product?.filing_name === type)

    return [...activeServices, ...cartItems, ...cartBundleItems, ...activeOrderItems]
  },
  hasProductOfTypeWithCompanyOrInCart: (state, getters) => (type, jurisdiction) => {
    return getters.productsOfTypeWithCompanyOrInCart(type, jurisdiction).length > 0
  },
  hasProductOfTypeAwaitingClientInputWithCompanyOrInCart: (state, getters, _rootState) => (type, jurisdiction) => {
    return getters.productsOfTypeWithCompanyOrInCart(type, jurisdiction).some(product => {
      return product.type === 'registered-agent' &&
        product.status === 'awaiting-client-input'
    })
  },
  hasProductBundleWithCompanyOrInCartByProductCategory: (state, getters, _rootState, rootGetters) => (productCategoryName) => {
    const productBundle = rootGetters['checkout/findProductBy']('product_bundle', { 'product_categorization.subcategory' : productCategoryName })
    if (!productBundle) return false

    const activeProductBundles = getters.activeProductBundles
    return activeProductBundles?.some(b => b?.product_categorization?.subcategory === productCategoryName) ||
      rootGetters['checkout/findCartItem'](productBundle.id)
  },
  hasProductBundleInCartByProductCategory: (state, getters, _rootState, rootGetters) => (productCategoryName) => {
    const productBundle = rootGetters['checkout/findProductBy']('product_bundle', { 'product_categorization.subcategory' : productCategoryName })
    if (!productBundle) return false

    return rootGetters['checkout/findCartItem'](productBundle.id)
  },
  domesticRegistration: (_state, getters) => getters.currentCompany?.domestic_registration,
  domesticRegistrationMailingAddress: (_state, getters) => getters.domesticRegistration?.details?.company_mailing_address,
  domesticRegistrationMailingAddressIsForeign: (_state, getters) => {
    const country = getters.domesticRegistrationMailingAddress?.country
    return (country ? !DOMESTIC.includes(country.trim().toLowerCase()) : false)
  },
  checkoutDomesticJurisdiction: (_state, getters, _rootState, rootGetters ) => {
    return rootGetters['jurisdictions/findByName'](getters.currentCompany?.config?.checkout_domestic_jurisdiction_name)
  },
  noJurisdictionForCompany: (_state, getters) => !getters.domesticRegistration && !getters.checkoutDomesticJurisdiction,
  domesticJurisdiction: (_state, getters) => {
    return getters.domesticRegistration?.jurisdiction || getters.checkoutDomesticJurisdiction
  },
  formedElsewhere: (_state, getters) => {
    return !getters.formationFiling &&
      ((getters.currentCompany?.config?.formed_elsewhere === true) ||
        !!getters.domesticRegistration?.file_date)
  },
  formationInfoObtained: (_state, getters) => {
    return !!getters.formationFiling ||
      !!getters.domesticRegistration?.file_date ||
      getters.currentCompany?.config?.formed_elsewhere != null
  },
  formationDateMissing: (_state, getters) => {
    return getters.currentCompany?.config?.formed_elsewhere &&
      getters.currentCompany?.config?.formation_date == null
  },
  alreadyFormed: (_state, getters) => {
    return !!getters.formationFiling ||
      !!getters.domesticRegistration?.file_date
  },
}

const ACTIONS = {
  ...BaseActions,
  async loadMore({ commit, getters }, useOffset = true ) {
    commit(t.LOAD_COMPANIES_START)
    try {
      const params = { limit: getters.resultsPerPage }
      if (useOffset) {
        params.offset = getters.count
      }
      const companies = await http.get('client/companies', { params })
      commit(t.LOAD_COMPANIES_FINISHED, companies.data.result)
    } catch (error) {
      commit(t.LOAD_COMPANIES_FAILED, error)
    }
  },
  async setCurrentCompany({ commit, dispatch, getters, rootGetters }, { id, force, preserveStageline }) {
    if (getters.currentCompanyId === id && !force) {
      return
    }
    await dispatch('loadById', { id: id })
    commit(t.SET_CURRENT_COMPANY, id)



    if (!preserveStageline) dispatch('stageline/resetStageline', null, { root: true })

    if (rootGetters['website/website'].stageline_version === 2) {
      dispatch('stagelineSchemaForm/resetStagelineSchemaForm', null, { root: true })
    }

    await dispatch('vouchers/fetchCompanyVouchers', id , { root: true })
    await dispatch('loadActiveItems', { id: id })
    await dispatch('checkout/setupContext', { companyId: id }, { root: true })
  },

  clearCurrentCompany({ commit }) {
    commit(t.SET_CURRENT_COMPANY, null)
  },

  // Allow other stores to update a company in the companies list
  async commitCompany({ commit }, company) {
    commit(t.LOAD_COMPANIES_FINISHED, [company])
  },

  updateCompanyDetails({ commit, getters }, company) {
    let companies = getters.companies
    let updateCompany = companies.find(c => c.id === company.id)

    if (updateCompany) {
      updateCompany.details = company.details
      commit(t.LOAD_COMPANIES_FINISHED, companies)
    }
  },

  async updateCompanyStage( { commit, getters }, params) {
    const company = await http.post(`client/companies/${getters.currentCompanyId}/update_company_stage`, params)
    commit(t.LOAD_COMPANIES_FINISHED, [company.data.result])
  },

  async loadIfNotAvailableById({ dispatch, getters }, { id }) {
    if (!getters.find(id)) {
      await dispatch('loadById', { id })
    }
  },

  async loadById({ commit }, { id }) {
    commit(t.LOAD_COMPANIES_START)

    try {
      const company = await http.get(`client/companies/${id}`)

      commit(t.LOAD_COMPANIES_FINISHED, [company.data.result])
    } catch (error) {
      commit(t.LOAD_COMPANIES_FAILED, error)
    }
  },

  async loadActiveServices({ commit }, { id }) {
    try {
      const services = await http.get(`client/services`, {
        params: {
          company_id: id,
          include_jurisdiction: true,
          type: SERVICE_TYPES_VALUES,
          status: OPERATING_STATUSES_VALUES,
          limit: 200,
        },
      })

      commit(t.SET_COMPANY_ACTIVE_SERVICES, services.data.result)
    } catch (error) {
      commit(t.LOAD_SERVICES_FAILED, error)
    }
  },
  async loadSuspendedServices({ commit }, { id }) {
    try {
      const services = await http.get(`client/services`, {
        params: {
          company_id: id,
          include_jurisdiction: true,
          type: SERVICE_TYPES_VALUES,
          status: 'suspended',
          limit: 200,
          include_virtual_phone: true,
        },
      })

      commit(t.SET_COMPANY_SUSPENDED_SERVICES, services.data.result)
    } catch (error) {
      commit(t.LOAD_SERVICES_FAILED, error)
    }
  },

  // Used to add active services created somewhere without have to load all of them again.
  // Takes in an array of services
  addActiveServices({ commit, getters }, services) {
    commit(t.SET_COMPANY_ACTIVE_SERVICES, [...getters.activeServices, ...services])
  },
  async loadActiveOrderItems({ commit }, { id }) {
    const res = await http.get(`client/order_items`, {
      params: {
        company_id: id,
        active: true,
        limit: 50,
      },
    })

    commit(t.SET_COMPANY_ACTIVE_ORDER_ITEMS, res.data.result)
  },

  // Used to add active order items created somewhere without have to load all of them again.
  // Takes in an array of order items
  addActiveOrderItems({ commit, getters }, orderItems) {
    commit(t.SET_COMPANY_ACTIVE_ORDER_ITEMS, [...getters.activeOrderItems, ...orderItems])
  },

  async loadActiveItems({ dispatch }, { id }) {
    await dispatch('loadActiveServices', { id: id })
    await dispatch('loadActiveSubscriptions', { id: id })
    await dispatch('loadActiveOrderItems', { id: id })
    await dispatch('loadActiveBundles', { id: id })
  },

  async loadActiveRAServices({ commit }, { id }) {
    try {
      const services = await http.get(`client/services`, {
        params: {
          company_id: id,
          include_jurisdiction: true,
          type: 'registered-agent',
          status: OPERATING_STATUSES_VALUES,
          limit: 200,
        },
      })
      commit(t.SET_COMPANY_ACTIVE_RA_SERVICES, services.data.result)
    } catch (error) {
      commit(t.LOAD_SERVICES_FAILED, error)
    }
  },

  async loadActiveComplianceServices({ commit }, { id }) {
    try {
      const services = await http.get(`client/services`, {
        params: {
          company_id: id,
          include_jurisdiction: true,
          type: 'compliance',
          status: OPERATING_STATUSES_VALUES,
          limit: 200,
        },
      })
      commit(t.SET_COMPANY_ACTIVE_COMPLIANCE_SERVICES, services.data.result)
    } catch (error) {
      commit(t.LOAD_SERVICES_FAILED, error)
    }
  },

  async loadActiveServiceByType({ commit }, { id, type }) {
    try {
      const services = await http.get(`client/services`, {
        params: {
          company_id: id,
          include_jurisdiction: false,
          type: type,
          status: ['active', 'trial-active'],
          limit: 200,
        },
      })
      commit(t.SET_COMPANY_ACTIVE_SERVICE_BY_TYPE, services.data.result, type)
    } catch (error) {
      commit(t.LOAD_SERVICES_FAILED, error)
    }
  },

  async loadActiveBundles({ commit }, { id }) {
    const res = await http.get(`client/companies/${id}/product_bundles`)
    commit(t.SET_COMPANY_ACTIVE_PRODUCT_BUNDLES, res.data.result)
  },

  async loadActiveSubscriptions({ commit }, { id }) {
    try {
      const subscriptions = await http.get(`client/subscriptions`, {
        params: {
          company_id: id,
          include_jurisdiction: true,
          status: OPERATING_STATUSES_VALUES,
          limit: 100,
        },
      })
      commit(t.SET_COMPANY_ACTIVE_SUBSCRIPTIONS, subscriptions.data.result)
    } catch (error) {
      commit(t.LOAD_SERVICES_FAILED, error)
    }
  },

  search: debounce(async ({ commit }, { name }) => {
    if (!name) {
      return
    }

    commit(t.LOAD_COMPANIES_START)

    try {
      const companies = await http.get(`client/companies/search`, { params: { name } })

      commit(t.LOAD_COMPANIES_FINISHED, companies.data.result)
    } catch (error) {
      commit(t.LOAD_COMPANIES_FAILED, error)
    }
  }, 300),

  setResultsPerPage({ commit }, count) {
    commit(t.SET_COMPANY_RESULTS_PER_PAGE, count)
  },
  async updateCheckoutDomesticJurisdiction({ dispatch, rootGetters }, { companyId, jurisdictionId, loadProducts }) {
    const jurisdiction = rootGetters['jurisdictions/findById'](jurisdictionId)
    const checkoutDomesticJurisdictionName = {
      checkout_domestic_jurisdiction_name: jurisdiction.state_province_region,
    }

    await dispatch('updateCompanyConfig', {
      companyId: companyId,
      config: checkoutDomesticJurisdictionName,
    }, { root: true })

    if (loadProducts) {
      dispatch('checkout/loadProducts', null, { root: true })
    }

    // TODO handle implementation or throw away
    // commit(t.LOAD_COMPANIES_FINISHED, [response.data.result])
  },

  // eslint-disable-next-line no-empty-pattern
  async saveStagelineCompanyData({}, { companyId, jurisdictionId, details }) {
    const response = await http.post(`client/companies/${companyId}/save_stageline_company_data`, {
      jurisdiction_id: jurisdictionId,
      details: details,
    })

    return response.data
  },
  async updateCompanyConfig({ dispatch }, { companyId, config }) {
    const company = await axiosClient.post(`client/companies/${companyId}/update_company_config`, {
      config: config,
    })

    dispatch('companies/commitCompany', company.data.result, { root: true })
  },
  async updateCompanyName({ dispatch }, { companyId, name }) {
    try {
      const response =  await http.post(`client/companies/${companyId}/update_company_name`, { name })

      if (response.data.success) {
        const updatedCompany = response.data.result
        await Promise.all([
          dispatch('setCurrentCompany', { id: updatedCompany.id, force: true }),
          dispatch('verifyOrder/loadLatestOrderRequiringVerification', { companyId: updatedCompany.id }, { root: true }),
          dispatch('checkout/loadCartItems', null, { root: true }),
        ])

        return updatedCompany
      } else {
        return null
      }
    } catch (error) {
      return null
    }
  },

  async updateCompanyNameSimple({ commit }, { companyId, name }) {
    try {
      const response =  await http.post(`client/companies/${companyId}/update_company_name`, { name })

      if (response.data.success) {
        commit(t.LOAD_COMPANIES_FINISHED, [response.data.result])
        return true
      }
    } catch { /**/ }

    return false
  },

  async updateDomesticRegistrationJurisdiction({ dispatch, commit }, { companyId, jurisdictionId, loadProducts }) {
    const response = await http.post(`client/companies/${companyId}/update_domestic_registration_jurisdiction`, {
      jurisdiction_id: jurisdictionId,
      update_previous_domestic_to_unknown: true,
    })

    if (loadProducts) {
      dispatch('checkout/loadProducts', null, { root: true })
    }

    commit(t.LOAD_COMPANIES_FINISHED, [response.data.result])
  },

  async fetchCompanyFilings({ rootGetters }, { params }) {
    try {
      const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
      return await client.call({
        method: GET,
        path: FILING_BY_COMPANY_AND_JURISDICTIONS.mapping({ companyId: params.company }, true),
        params: { jurisdiction_id: params.jurisdiction, company_id: params.company },
      })
    } catch (error) {
      return error
    }
  },
  async fetchRegistrations({ commit }, { companyId }) {
    try {
      commit(t.SET_COMPANY_REGISTRATIONS, [])
      const response = await http.get(`client/companies/${companyId}/registrations`)
      const registrations = response.data.result
      commit(t.SET_COMPANY_REGISTRATIONS, registrations)
    } catch (error) {
      commit(t.SET_COMPANY_REGISTRATIONS, [])
    }
  },
}

const MUTATIONS = {
  ...BaseMutations,
  [t.LOAD_COMPANIES_START](state) {
    state.companiesLoaded = false
  },

  [t.LOAD_COMPANIES_FINISHED](state, companies) {
    companies.forEach(c => Vue.set(state.companies, c.id, c))

    state.companiesLoaded = true
  },

  [t.SET_COMPANY_RESULTS_PER_PAGE](state, count) {
    state.resultsPerPage = count
  },

  [t.LOAD_COMPANIES_FAILED](state, error) {
    state.error = error
  },

  [t.SET_CURRENT_COMPANY](state, companyId) {
    state.currentCompanyId = companyId
  },

  [t.SET_COMPANY_ACTIVE_SERVICES](state, services) {
    state.activeServices = services
  },

  [t.SET_COMPANY_SUSPENDED_SERVICES](state, services) {
    state.suspendedServices = services
  },

  [t.SET_COMPANY_ACTIVE_ORDER_ITEMS](state, orderItems) {
    state.activeOrderItems = orderItems
  },

  [t.SET_COMPANY_ACTIVE_RA_SERVICES](state, services) {
    state.activeServices = [...state.activeServices.filter(service => service.type !== 'registered-agent'), ...services]
  },

  [t.SET_COMPANY_ACTIVE_COMPLIANCE_SERVICES](state, services) {
    state.activeServices = [...state.activeServices.filter(service => service.type !== 'compliance'), ...services]
  },

  [t.SET_COMPANY_ACTIVE_SERVICE_BY_TYPE](state, services, serviceType) {
    state.activeServices = [...state.activeServices.filter(service => service.type !== serviceType), ...services]
  },

  [t.SET_COMPANY_ACTIVE_SUBSCRIPTIONS](state, subscriptions) {
    state.activeSubscriptions = subscriptions
  },

  [t.SET_COMPANY_ACTIVE_PRODUCT_BUNDLES](state, productBundles) {
    state.activeProductBundles = productBundles
  },

  [t.LOAD_SERVICES_FAILED](state, error) {
    state.servicesError = error
  },
  [t.SET_COMPANY_REGISTRATIONS](state, registrations) {
    state.registrations = registrations
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
