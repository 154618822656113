import { Client, POST } from '@/api/client'
import {
  ADD_COMPLIANCE_SERVICE,
} from '@/api/v3/endpoints'

import {
  state as BaseState,
  actions as BaseActions,
  getters as BaseGetters,
  mutations as BaseMutations,
} from '@/store/base'
import http, { paramsSerializer } from '@/http'
import * as t from '@/store/mutations'

const STATE = {
  ...BaseState,
  namespace: 'services',
  services: [],
  selectedServices: [],
}

const GETTERS = {
  ...BaseGetters,
  services: state => state.services,
  companyServices: state => companyId => state.services.filter(svc => svc.company_id === companyId),
  selectedServices: state => state.selectedServices,
}

const ACTIONS = {
  ...BaseActions,
  async getServices({ commit }, params) {
    const { data } = await http.get(`client/services`, { params, paramsSerializer })
    if(data?.success){
      commit(t.SET_SERVICES, data.result)
    }

    return data
  },
  async getAndReloadServices({ commit }, { params, ids }) {
    if(!ids.length) return
    const filter = [{ scope: 'filter_by_id', value: ids }]
    const { data } = await http.get(`client/services`, { params: { ...params, filter }, paramsSerializer })
    if(data?.success){
      commit(t.ADD_UPDATE_SERVICES, data.result)
    }

    return data
  },
  async addComplianceService({ _commit, rootGetters }, { params }) {
    const client = new Client(null, rootGetters['session/getToken'], rootGetters['session/getExpiration'])
    try {
      const payload = { autopay_payable_id: params.autopayPayableId, ...params }

      const response = await client.call({
        method: POST,
        path: ADD_COMPLIANCE_SERVICE.mapping({
          ':companyId': params.companyId,
          ':registrationId': params.registrationId,
        }),
        params: payload,
      })

      return response
    } catch (error) {
      console.log(error)
      return error
    }
  },
}

const MUTATIONS = {
  ...BaseMutations,
  [t.SET_SERVICES](state, services) {
    state.services = services
  },
  [t.ADD_UPDATE_SERVICES](state, services) {
    state.services = [
      ...state.services.filter(svc => services.every(s => s.id !== svc.id)),
      ...services,
    ]
    state.selectedServices = [
      ...services.filter(svc => state.selectedServices.some(ss => ss.id === svc.id)),
      ...state.selectedServices.filter(ss => services.every(svc => svc.id !== ss.id)),
    ]
  },
  [t.SET_SELECTED_SERVICES](state, services) {
    state.selectedServices = services
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
