import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
import store from '../store'
import { createOrFindClientInteractionLog, logClientInteraction } from '@/common/modules/clientInteractionLog'
import * as Cookies from 'js-cookie'
import { SET_FROM } from '@/store/mutations'
import { initialize, redirect, logRouterTransition } from '@/common/modules/initialize'
import Keycloak from 'keycloak-js'

const authRequired = { meta: { requiresAuth: true } }

const NestedRoute = () => import('@/components/shared/NestedRoute')

const router = new Router({
  routes: [
    {
      path: '/hire-us',
      name: 'hire-us',
      component: () => import('@/pages/HireUs/HireUs'),
      props: true,
      children: [
        {
          path: '',
          name: 'choose-company',
          props: true,
          component: () => import('@/pages/HireUs/ChooseCompany'),
        },
        {
          path: 'add-company',
          name: 'add-company',
          component: () => import('@/pages/HireUs/AddNewCompany'),
        },
        {
          path: ':companyId/choose-category',
          name: 'choose-category',
          props: true,
          component: () => import('@/pages/HireUs/ChooseBucket'),
          meta: {
            showCart: true,
          },
        },
        {
          path: ':companyId/:bucketId/:categoryId?/choose-product-category',
          name: 'choose-product-category',
          props: true,
          component: () => import('@/pages/HireUs/ChooseProductCategory'),
          meta: {
            showCart: true,
          },
        },
        // TODO: remove at a later time, keeping just in case it is being used
        // {
        //   path: ':companyId/review/:invoiceId',
        //   name: 'review',
        //   props: true,
        //   component: () => import('@/pages/HireUs/Review'),
        // },
        {
          path: ':companyId/order-confirmation/:invoiceId',
          name: 'order-confirmation',
          component: () => import('@/pages/HireUs/OrderConfirmation'),
        },
        {
          path: ':companyId/:categoryId?/optional-items/:productId?',
          name: 'optional-items',
          props: true,
          component: () => import('@/pages/HireUs/OptionalItems'),
          meta: {
            showCart: true,
            showSummary: true,
          },
        },
        {
          path: ':companyId/:bucketId/:categoryId/:productKind?/choose-product',
          name: 'choose-product',
          props: true,
          component: () => import('@/pages/HireUs/ChooseProduct'),
          meta: {
            showCart: true,
          },
        },
        {
          path: ':companyId/:productKind',
          name: 'choose-bundle',
          props: true,
          component: () => import('@/pages/HireUs/ChooseBundle'),
          meta: {
            showCart: true,
          },
        },
        {
          path: ':companyId/post-payment/:invoiceId',
          name: 'post-payment',
          props: true,
          component: () => import('@/pages/HireUs/PostPayment'),
        },
        {
          path: ':companyId/checkout/required-info/:invoiceId',
          name: 'checkout-required-info',
          props: true,
          component: () => import('@/components/HireUs/PostPaymentItemsRequiringAttention'),
          meta: {
            showCart: false,
          },
        },
        {
          path: ':companyId/checkout/payment',
          name: 'checkout-payment',
          props: true,
          component: () => import('@/pages/HireUs/CheckoutPayment'),
          meta: {
            showCart: true,
            showSummary: true,
          },
        },
        {
          path: ':companyId/:bucketId/:categoryId?/:productKind?/product-details/:productId?',
          name: 'product-details',
          props: true,
          component: () => import('@/pages/HireUs/ProductDetails'),
          meta: {
            showCart: true,
            showSummary: true,
          },
        },
      ],
    },
    {
      path: '/agreement_languages/:agreement_language_type',
      name: 'cart',
      props: true,
      component: () => import('@/components/HireUs/Cart'),
    },
    {
      path: '/contact-support',
      name: 'contact-support',
      props: true,
      component: () => import('@/pages/ContactSupport'),
    },
    {
      path: '/guest-pay/:id',
      name: 'guest-pay',
      component: () => import('@/pages/GuestPay'),
    },
    {
      ...authRequired,
      path: '/',
      alias: ['/free-account'],
      name: 'root',
      beforeEnter: async (to, from, next) => {

        if (window.location.search) {
          const urlParams = new URLSearchParams(window.location.search)
          const adminParam = urlParams.get('admin')
          const domainAdminParam = urlParams.get('domain_admin')

          // Mark invoices created through the admin login.
          if (adminParam) {
            sessionStorage.setItem('admin-logged-in', 'true')
          }

          // Add domain admin flag to session storage
          if (domainAdminParam) {
            sessionStorage.setItem('domain-admin', 'true')
          }

          // Remove url search params
          history.replaceState({}, document.title, window.location.href.replace(/\?.*/, ''))
        }

        //Do we show card processing ad?
        const ownerID = store.getters['account/account']['id']
        await store.dispatch('creditCardProcessing/getProcessingAdStatus', ownerID)

        const website = store.getters['website/website']
        const isRegularAccount = store.getters['account/isRegularAccount']

        const redirected = await redirect(website, isRegularAccount, next)

        if (!redirected) {
          if (website.stageline_enabled && isRegularAccount) {
            next({ name: website.stageline_version === 2 ? 'stageline-v2-landing-page' : 'stageline-v1-landing-page' })
          } else {
            next('/dashpanel')
          }
        }
      },
    },
    {
      ...authRequired,
      path: '/more',
      name: 'more',
      component: () => import('@/components/navs/MobileMore'),
    },
    {
      path: '/dashpanel',
      name: 'dashpanel',
      component: () => import('@/pages/Dashpanel'),
    },
    {
      path: '/autopayments',
      name: 'autopayments',
      component: () => import('@/pages/Autopayments'),
    },
    {
      path: '/autopayments/:companyId',
      name: 'companyAutopayments',
      component: () => import('@/pages/CompanyAutopayments'),
    },
    {
      path: '/manage-autopay',
      name: 'manageAutopay',
      props: (route) => ({
        serviceIds: route.query.serviceIds
          ? route.query.serviceIds.split(',')
          : [],
      }),
      component: () => import('@/pages/Payments/ManageAutopay'),
    },
    {
      path: '/pending-filings',
      name: 'pendingFilings',
      component: () => import('@/pages/PendingFilings'),
    },
    {
      path: '/pending-filings/:orderId/:orderItemId',
      name: 'filingStatus',
      component: () => import('@/pages/FilingStatus'),
    },
    {
      path: '/pending-vehicle-orders',
      name: 'pendingVehicleOrders',
      component: () => import('@/pages/PendingVehicleOrders'),
    },
    {
      path: '/phones',
      name: 'phones',
      component: () => import('@/pages/Phones'),
    },
    {
      path: '/monitoring',
      name: 'monitoring',
      component: () => import('@/pages/Monitoring/MonitoringDashboard'),
      children: [
        {
          path: '',
          name: 'monitoring-overview',
          component: () => import('@/pages/Monitoring/MonitoringOverviewPage'),
          meta: {
            init: ['monitoringSession'],
          },
        },
        {
          path: 'reports',
          name: 'monitoring-reports',
          component: () => import('@/pages/Monitoring/MonitoringReportsPage'),
        },
        {
          path: 'keywords',
          name: 'monitoring-keywords',
          component: () => import('@/pages/Monitoring/MonitoringKeywordsPage'),
        },
        {
          path: 'trademarks',
          name: 'monitoring-trademarks',
          component: () => import('@/pages/Monitoring/MonitoringTrademarksPage'),
        },
        {
          path: 'companies',
          name: 'monitoring-companies',
          component: () => import('@/pages/Monitoring/MonitoringCompaniesPage'),
        },
        {
          path: 'domains',
          name: 'monitoring-domains',
          component: () => import('@/pages/Monitoring/MonitoringDomainsPage'),
        },
        {
          path: 'Manage',
          name: 'monitoring-manage',
          component: () => import('@/pages/Monitoring/MonitoringManagePage'),
        },
      ],
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/pages/VirtualPhoneSettings'),
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: () => import('@/pages/VirtualPhoneContacts'),
    },
    {
      path: '/voicemails/:virtualPhoneId?',
      props: true,
      name: 'voicemails',
      component: () => import('@/pages/Voicemails'),
    },
    {
      path: '/publications/company-selection',
      name: 'publications/companySelection',
      component: () => import('@/pages/Publications/CompanySelection'),
    },
    {
      path: '/services',
      name: 'services',
      component: () => {
        const isAccountWholesaler = store.getters['account/isAccountWholesaler']
        const services_count = store.getters['account/activeServicesCount']
        if (isAccountWholesaler || services_count >= 10) {
          return import('@/pages/WholesalerServices')
        } else {
          return import('@/pages/Services')
        }
      },
      beforeEnter: async (to, from, next) => {
        await store.dispatch('account/loadAccount')
        await store.dispatch('account/setActiveServicesCount')
        next()
      },
    },
    {
      path: '/services-requiring-attention',
      name: 'servicesRequiringAttention',
      component: () => import('@/pages/ServicesRequiringAttention'),
    },
    {
      path: '/order-items-requiring-attention',
      name: 'orderItemsRequiringAttention',
      component: () => import('@/pages/OrderItemsRequiringAttention'),
      meta: {
        showCart: true,
      },
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: () => import('@/pages/Invoices'),
    },
    {
      path: '/pay-invoices',
      name: 'payInvoices',
      props: (route) => ({ invoiceIds: route.query.invoiceIds
            ? route.query.invoiceIds.split(',')
            : [],
      }),
      component: () => import('@/pages/Payments/PayInvoices.vue'),
    },
    {
      path: '/invoices/:id',
      name: 'invoice',
      component: () => import('@/pages/Invoice'),
    },
    {
      path: '/make-a-filing',
      component: NestedRoute,
      children: [
        {
          path: 'company-selection',
          name: 'companySelection',
          component: () => import('@/pages/MakeAFiling/InitialSelection'),
        },
        {
          path: 'jurisdiction-filings/:companyId/:jurisdictionId',
          name: 'jurisdictionFilings',
          component: () => import('@/pages/MakeAFiling/JurisdictionFilings'),
        },
        {
          path: 'new-company-selection',
          name: 'newCompanySelection',
          component: () => import('@/pages/MakeAFiling/NewCompanySelection'),
        },
        {
          path: ':companyId/:jurisdictionId/filing-selection',
          name: 'filingSelection',
          component: () => import('@/pages/MakeAFiling/FilingSelection'),
        },
        {
          path: ':companyId/:jurisdictionId/:filingId/filing-method-selection',
          name: 'filingMethodSelection',
          component: () => import('@/pages/MakeAFiling/FilingMethodSelection'),
        },
        {
          path: ':companyId/:jurisdictionId/:filingId/:filingMethodId/resource-selection',
          name: 'resourceSelection',
          component: () => import('@/pages/MakeAFiling/ResourceSelection'),
        },
      ],
    },
    {
      path: '/company-details',
      name: 'companyDetails',
      props: false,
      component: () => import('@/components/CompanyDetails/CompanyDetails.vue'),
    },
    {
      path: '/stageline-v2/decisionTree',
      name: 'stageline-v2/decisionTree',
      props: false,
      component: () => import('@/pages/StagelineV2/DecisionTreePage'),
    },
    {
      path: '/registrations',
      name: 'registrations',
      component: () => import('@/pages/Registrations'),
    },
    {
      path: '/login/:websiteId?',
      name: 'login',
      component: () => import('@/pages/Login'),
    },
    {
      path: '/free-account-signup/:websiteId/:emailHash',
      name: 'freeAccountSignup',
      component: () => import('@/pages/FreeAccountSignup'),
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: () => import('@/pages/ForgotPassword'),
    },
    {
      path: '/reset/:token',
      name: 'reset',
      component: () => import('@/pages/ResetPassword'),
    },
    {
      path: '/documents',
      name: 'documents',
      component: () => import('@/pages/Documents'),
    },
    {
      path: '/documents/:id',
      name: 'document',
      component: () => import('@/pages/Document'),
    },
    {
      path: '/documents/:id/:page',
      name: 'documentPage',
      component: () => import('@/pages/Document'),
    },
    {
      path: '/websites',
      name: 'websites',
      props: true,
      component: () => import('@/pages/Websites'),
    },
    {
      path: '/websites/:id',
      name: 'website',
      props: true,
      component: () => import('@/pages/Website'),
    },
    {
      path: '/websites/:id/manage_email',
      name: 'manageEmail',
      redirect: to => {
        return {
          name: 'website', params: { id: to.params.id, activeTab: 'email' },
        }
      },
    },
    {
      path: '/authorize-foa/:company_id/:token',
      name: 'authorizeFoa',
      component: () => import('@/pages/FoaAuthorization'),
    },
    {
      path: '/law-on-call',
      component: NestedRoute,
      children: [
        {
          path: '/',
          name: 'law-on-call',
          component: () => import('@/pages/LawOnCall/LegalRequests'),
        },
        {
          path: 'new-legal-request',
          name: 'new-legal-request',
          component: () => import('@/pages/LawOnCall/NewLegalRequest'),
        },
      ],
      beforeEnter: async (to, from, next) => {
        const activeServices = store.getters['dashpanel/activeLawOnCallServices']

        if (activeServices && activeServices.length) {
          next()
        } else {
          next(from)
        }
      },
    },
    {
      path: '/account/account-settings',
      name: 'accountSettings',
      component: () => import('@/pages/AccountSettings'),
      props: true,
    },
    {
      path: '/account/contact-list',
      name: 'contactList',
      component: () => import('@/pages/ContactList'),
      props: { mastheadTitle: 'People' },
    },
    {
      path: '/account/invitations',
      name: 'invitations',
      component: () => import('@/pages/Invitations'),
    },
    {
      path: '/account/shared-settings',
      name: 'sharedSettings',
      component: () => import('@/pages/SharedSettings'),
    },
    {
      path: '/account/change-password',
      name: 'changePassword',
      component: () => import('@/pages/ChangePassword'),
      beforeEnter: async () => {
        if (store.state.session.keycloakAuthenticated) {
          if (store.state.session.keycloakConfig.realm === 'epik.com') {
            window.location.replace("https://id.epik.com/auth/realms/epik/account/")
          } else {
            store.state.session.keycloak.login({
              action: "UPDATE_PASSWORD",
            })
          }
        }
      },
    },
    {
      path: '/account/mobile-access',
      name: 'mobileAccess',
      component: () => import('@/components/shared/MobileAccess'),
      props: true,
    },
    {
      path: '/account/payment-methods',
      name: 'paymentMethods',
      component: () => import('@/pages/Payments/PaymentMethods.vue'),
    },
    {
      path: '/account/signature',
      name: 'signature',
      component: () => import('@/pages/Signature'),
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('@/pages/Faq/Faq'),
    },
    {
      path: '/faq/:categoryId',
      name: 'categoryId',
      component: () => import('@/pages/Faq/FaqCategory'),
    },
    {
      path: '/faq/:categoryId/:pageId',
      name: 'faqPage',
      component: () => import('@/pages/Faq/FaqPage'),
    },
    {
      path: '/vehicle-registrations',
      name: 'vehicleRegistrations',
      component: () => import('@/pages/VehicleRegistrations'),
    },
    {
      path: '/stageline',
      name: 'stageline-v1-landing-page',
      component: () => import('@/pages/StagelineV1/StagelineLandingPageV1'),
      meta: {
        showCart: false,
      },
    },
    {
      path: '/stageline/:companyId',
      name: 'stageline-page',
      component: () => import('@/pages/StagelineV1/StagelinePageV1'),
      meta: {
        showCart: true,
      },
      children: [
        {
          path: ':stagelineType',
          name: 'stageline',
          props: true,
          meta: {
            showCart: true,
          },
          component: () => import('@/components/StagelineV1/Stageline'),
          beforeRouteEnter(_to, _from, _next) {
            // TODO Prevent navigation to /form stageline unless enabled on account
            // next(vm => {
            //   if (this.store.getters['stageline/company'].config.formed_elsewhere
            //     || this.store.getters['stageline/company'].stagelineComplete(from.params)) {
            //     console.log("No access to form stageline, preventing route change.");
            //     next(from)
            //   }
            // });
          },
          children: [
            {
              path: 'checkout/payment',
              name: 'stageline-checkout-payment',
              component: () => import('@/pages/HireUs/CheckoutPayment'),
              meta: {
                showCart: true,
              },
            },
            {
              path: 'checkout/post-payment',
              name: 'stageline-post-payment',
              props: true,
              component: () => import('@/components/StagelineV1/steps/checkout/StagelinePostPayment'),
              meta: {
                showCart: false,
              },
            },
            {
              path: 'checkout/required-info/:invoiceId',
              name: 'stageline-required-info',
              props: true,
              component: () => import('@/components/HireUs/PostPaymentItemsRequiringAttention'),
              meta: {
                showCart: false,
              },
            },
          ],
        },
      ],
    },
    {
      path: '/createCompany',
      name: 'createCompany',
      props: false,
      component: () => import('@/pages/StagelineV2/CreateCompanyPage'),
    },
    {
      path: '/stageline-v2/:companyId/decisionTree',
      name: 'stageline-v2-decision-tree',
      props: false,
      component: () => import('@/pages/StagelineV2/DecisionTreePage'),
    },
    {
      path: '/stageline-v2',
      name: 'stageline-v2-landing-page',
      component: () => import('@/pages/StagelineV2/StagelineLandingPage'),
      meta: {
        showCart: false,
        init: ['stagelineCompanies'],
      },
    },
    {
      path: '/stageline-v2/end-page',
      name: 'stageline-v2-end-page',
      component: () => import('@/pages/StagelineV2/StagelineEndPage'),
      meta: {
        showCart: false,
      },
    },
    {
      path: '/stageline-v2/:companyId/',
      name: 'stageline-v2-page',
      component: () => import('@/pages/StagelineV2/StagelinePage'),
      meta: {
        showCart: true,
      },
      beforeEnter: async (to, from, next) => {
        const source = to?.query?.source

        if (source) {
          const clientInteractionLog = await createOrFindClientInteractionLog({
            category: 'email-url',
            subCategory: source,
            companyId: to?.params?.companyId,
          })

          await logClientInteraction({
            clientInteractionLogId: clientInteractionLog.id,
            interaction: { type: 'email-url', action: 'link', name: source },
          })
        }

        next()
      },
      children: [
        // Link to stageline for company (uses company StagelineRoute)
        // example: /stageline-v2/97ea753e-1390-4f82-b29d-474c85bfb9fb/start
        {
          path: 'start',
          name: 'stageline-v2-start',
          component: () => import('@/components/StagelineV2/Stageline'),
          props: false,
          meta: {
            showCart: true,
          },
        },
        {
          path: 'overview',
          name: 'overview',
          beforeEnter: async (to, _from, next) => {
            const { companyId, slideId } = to.params

            if (companyId) {
              await store.dispatch('companies/setCurrentCompany', { id: companyId })
            } else {
              next({ name: 'root' })
            }

            if (slideId) {
              const slide = store.getters['stageline/getSlideByIdOrName'](slideId)
              await store.dispatch('stageline/setCurrentSlide', slide)
            }

            next()
          },
          component: () => import('@/pages/StagelineV2/CompanyOverviewPage'),
          props: false,
          meta: {
            showCart: false,
          },
        },
        // Navigate directly to a slide in Stageline.
        // example: /stageline-v2/97ea753e-1390-4f82-b29d-474c85bfb9fb/slide/415e75be-4cb3-4487-9067-d3abcd48dcbc
        {
          path: 'slide/:slideId',
          name: 'stageline-v2-slide',
          component: () => import('@/components/StagelineV2/Stageline'),
          props: false,
          meta: {
            showCart: true,
          },
        },
        {
          path: 'stage/:stageIdOrName',
          name: 'stageline-v2-stage',
          component: () => import('@/components/StagelineV2/Stageline'),
          props: false,
          meta: {
            showCart: true,
          },
        },
        {
          path: ':stagelineType/',
          name: 'stageline-type',
          props: true,
          component: () => import('@/components/StagelineV2/Stageline'),
          meta: {
            showCart: true,
          },
          children: [
            {
              path: 'checkout/payment',
              name: 'stageline-v2-checkout-payment',
              component: () => import('@/pages/HireUs/CheckoutPayment'),
              meta: {
                showCart: true,
              },
            },
            {
              path: 'checkout/required-info/:invoiceId',
              name: 'stageline-v2-required-info',
              props: true,
              component: () => import('@/components/HireUs/PostPaymentItemsRequiringAttention'),
              meta: {
                showCart: false,
              },
            },
          ],
        },
      ],
    },
    {
      path: '/verify-order',
      name: 'verify-order',
      beforeEnter: async (to, from, next) => {
        const website = store.getters['website/website']
        const stagelineV2Enabled = website && website?.stageline_enabled && website.stageline_version === 2

        const orderRequiringVerification = store.getters['verifyOrder/orderRequiringVerification']
        const formACompanyOrder = orderRequiringVerification && orderRequiringVerification?.product?.filing_name === 'form a company'
        const isRegularAccount = store.getters['account/isRegularAccount']
        const correctEntityType = (orv) => {
          if (!orv) return false
          return ['Limited Liability Company', 'Corporation'].includes(orv?.company?.entity_type)
        }

        if (stagelineV2Enabled && formACompanyOrder && isRegularAccount && correctEntityType(orderRequiringVerification)) {
          const companyId = orderRequiringVerification.company?.id || orderRequiringVerification.company_id
          const onStageline = (router.currentRoute?.name === 'stageline-v2-start' &&
            router.currentRoute?.params?.companyId === companyId)

          if (onStageline) await store.dispatch('stageline/goToVerifyOrderSlide')
          next({ name: 'stageline-v2-start', params: { companyId: companyId } })
        } else {
          if (!store.getters['verifyOrder/orderRequiringVerification']) {
            await store.dispatch('verifyOrder/loadLatestOrderRequiringVerification', {})
          }
          next()
        }
      },
      component: () => import('@/pages/VerifyOrder'),
    },
    {
      path: '/*',
      alias: '/404',
      name: 'notFound',
      component: () => import('@/pages/NotFound'),
    },
  ],
})

router.is = path => this.currentRoute.name === path

router.beforeEach(async (to, from, next) => {
  await store.dispatch('session/setPreviousLink', { previousLink: from.path })
  await store.dispatch('app/setRouteLoading', { isLoading: true })

  if (!store.state.session.keycloakConfig) {
    let strings = location.hostname.split('.')
    let realm = `${strings.at(-2)}.${strings.at(-1)}`

    await store.dispatch('session/fetchKeycloakConfig', { realm })
  }

  if (store.state.session.keycloakConfig) {
    if (!store.state.session.keycloak) {
      const keycloak = new Keycloak({
        url: 'https://id.staging.corporatetools.com/auth',
        realm: store.state.session.keycloakConfig.realm,
        clientId: store.state.session.keycloakConfig.clientId,
      })

      await store.dispatch('session/setKeycloak', { keycloak })
    }

    if (to.path.includes("/keycloak")) {
      try {
        await store.state.session.keycloak.init({ onLoad: "login-required" })
        await store.commit('session/setToken', { token: store.state.session.keycloak.token })
        await store.commit('session/setKeycloakAuthenticated', { keycloakAuthenticated: true })
        await store.commit('session/setExpiration', { expiration: store.state.session.keycloak.tokenParsed.exp })
        await store.dispatch('session/updateAccount', null, { root: true })
      } catch (error) {
        console.error('Failed to initialize adapter:', error)
      }
    }
  }

  let expiration = store.state.session.expiration

  if (Cookies.get('customer_token')) {
    const token = Cookies.get('customer_token')
    if (token !== 'done') {
      expiration = JSON.parse(atob(token.split('.')[1])).exp
      store.commit('session/setToken', { token })
      store.commit('session/setExpiration', { expiration })
      await store.dispatch('session/updateAccount', null, { root: true })
      Cookies.set('customer_token', 'done')
    }
  } else if (Cookies.get('token')) {
    const token = Cookies.get('token')
    const isNotAccountOwner = Cookies.get('is_not_account_owner')
    expiration = JSON.parse(atob(token.split('.')[1])).exp

    if (isNotAccountOwner != null) {
      await store.dispatch('account/setIsNotAccountOwner', isNotAccountOwner)
    }

    store.commit('session/setToken', { token })
    store.commit('session/setExpiration', { expiration })
  }

  const accountId = store.state.session.accountId

  const date = new Date()
  const nowUnix = date.getTime() / 1000

  // Refresh the token if it is within 30 minutes of expiring.
  const refreshWindowStart = expiration - (60 * 30)
  const refreshWindowEnd = expiration
  const isTokenInRefreshWindow = (nowUnix > refreshWindowStart && nowUnix < refreshWindowEnd)

  if (accountId && isTokenInRefreshWindow) {
    try {
      await store.dispatch('session/refresh', null, { root: true })
    } catch (error) {
      global.capturePathAndClearState()
    }
  }

  if (!['/login'].includes(to.path) || to.path.search('/viewer') === -1) {
    store.commit(SET_FROM, { from: to.path })
  }

  const isAuthRequired = global.routesIgnoringAuth.filter(route => to.path.search(route) !== -1).length === 0
  const isTokenMissing = !store.state.session.token
  const isTokenExpired = expiration && expiration <= nowUnix && !window.Cypress

  if (isAuthRequired && (isTokenMissing || isTokenExpired)) {
    const path = to.fullPath
    await store.dispatch('session/setRedirectUrl', { redirectUrl: path })
    global.capturePathAndClearState()

    store.state.session.keycloakConfig ? next('/login/keycloak') : next('/login')
  } else {
    if (isAuthRequired && !(isTokenMissing || isTokenExpired)) {
      await initialize(to.meta?.init || [])
      logRouterTransition(to, from)
      if (to.path.match(/stageline/)) {
        const stagelineVersion = store.getters['website/website'].stageline_version

        const stagelineV1route = !!to.path.match(/stageline(?!-v2)/)
        const stagelineV2route = !!to.path.match(/stageline-v2/)

        if (stagelineV1route && stagelineVersion === 2) {
          await next({ name: 'stageline-v2-landing-page' })
          return
        } else if (stagelineV2route && stagelineVersion === 1) {
          await next({ name: 'stageline-v1-landing-page' })
          return
        }
      }
    }
    next()
  }
})

router.afterEach(async () => {
  await store.dispatch('app/setRouteLoading', { isLoading: false })
  await store.dispatch('feed/refreshFeedNotifications')
})

export default router
