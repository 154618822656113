import * as t from '../mutations'
import http from '@/http'

const STATE = {
  entityTypes: [],
  entityTypeSuffixes: [],
}

const GETTERS = {
  entityTypes: state => state.entityTypes,
  entitySelectionValues: state => [ { value: null, text: 'Please select an entity type' }]
    .concat(state.entityTypes.map(et => {
      return { value: et.id, text: et.name }
    })),
  corporationEntityType: state => state.entityTypes.find(et => et.name == 'Corporation'),
  llcEntityType: state => state.entityTypes.find(et => et.name == 'Limited Liability Company'),
  nonLLCAndCorpEntityTypes: (state, getters) => [{ value: null, text: 'Other Entity Types', disabled: true }]
    .concat(state.entityTypes.filter(et => et.id != getters.llcEntityType.id && et.id != getters.corporationEntityType.id)
      .map(et => {
        return { value: et.id, text: et.name }
      })
    ),
  entityTypeSuffixes: state => state.entityTypeSuffixes,
}

const ACTIONS = {
  async getEntityTypes({ commit }) {
    const response = await http.get('client/entity_types')
    const entityTypes = response.data.result.filter(et => et.name !== 'Doing Business as Name')
    commit(t.SET_ENTITY_TYPES, entityTypes)
  },

  async getEntityTypeSuffixes({ commit }, { entityTypeId, jurisdictionId }) {
    const response = await http.get(`client/entity_suffixes/`, {
      params: {
        jurisdiction_id: jurisdictionId,
        entity_type_id: entityTypeId,
      },
    })

    let entityTypeSuffixes = []

    if (response.data.result.length && response.data.result[0].suffixes) {
      const suffixArray = [{ value: null, text: 'Select Entity Suffix', disabled: true }]
        .concat(response.data.result[0].suffixes.data.map(s =>  {
          return { value: s, text: s }
        }))
      entityTypeSuffixes = suffixArray
    }

    commit(t.SET_ENTITY_TYPE_SUFFIXES, entityTypeSuffixes)
  },
}

const MUTATIONS = {
  [t.SET_ENTITY_TYPES](state, entityTypes) {
    state.entityTypes = entityTypes
  },
  [t.SET_ENTITY_TYPE_SUFFIXES](state, entityTypeSuffixes) {
    state.entityTypeSuffixes = entityTypeSuffixes
  },
}

export default {
  namespaced: true,
  state: STATE,
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
}
