import * as t from '../mutations'
import http from '@/http'
import { filterMatch } from '@/helpers'
import { sortBy } from 'lodash/collection'

const modalFields = {
  selectedAreaCode: null,
  selectedAvailableNumber: null,
  availableNumbers: [],
  phoneName: '',
  forwardToNumber: null,
  forwardAs: null,
  forwardType: '',
  availableNumbersFinished: false,
  mobileAccessFinished: false,
  modalSubmissionFinished: false,
  modalSubmissionInProgress: false,
}

const phoneFields = {
  selectedVirtualPhone: null,
  virtualPhonesFinished: false,
  suspendedPhonesFinished: false,
  virtualPhones: [],
  customVoicemailUrl: null,
  voicemailLifespanInDays: null,
  virtualPhoneCnamParams: null,
  ringCount: null,
  greetingType: null,
  selectedPhoneId: null,
  virtualPhonesPendingRemoval: null,
  phoneServiceTrialEligible: null,
}

const voicemailFields = {
  voicemailsFinished: false,
  voicemailsForSelectedPhone: [],
  selectedVoicemailId: null,
  voicemailsSignedUrlFinished: false,
  selectedVoicemailSignedMediaUrl: null,
}

const state = {
  ...modalFields,
  ...phoneFields,
  ...voicemailFields,
  asteriskAuth: null,
  phoneNotificationCount: 0,
  virtualPhonesToConfigure: [],
  ringtoneOptions: [],
  ringtoneExample: null,
  blockedNumbers: [],
  callLogs: [],
  suspendedPhones: [],
}

const getters = {
  virtualPhonesFinished: state => state.virtualPhonesFinished,
  voicemailsFinished: state => state.voicemailsFinished,
  suspendedPhonesFinished: state => state.suspendedPhonesFinished,
  voicemailsSignedUrlFinished: state => state.voicemailsSignedUrlFinished,
  availableNumbersFinished: state => state.availableNumbersFinished,
  modalSubmissionFinished: state => state.modalSubmissionFinished,
  modalSubmissionInProgress: state => state.modalSubmissionInProgress,
  mobileAccessFinished: state => state.mobileAccessFinished,
  virtualPhonesPendingRemoval: state => state.virtualPhonesPendingRemoval,

  callLogs: state => state.callLogs,
  selectedAreaCode: state => state.selectedAreaCode,
  selectedPhoneId: state => state.selectedPhoneId || state.virtualPhones[0].id,
  selectedPhone: (state, getters) => getters.virtualPhoneById(state.selectedPhoneId || state.virtualPhones[0].id),
  selectedVoicemail: (state, getters) => getters.voicemailById(state.selectedVoicemailId),
  selectedVoicemailSignedMediaUrl: (state) => state.selectedVoicemailSignedMediaUrl,
  selectedAvailableNumber: (state) => state.selectedAvailableNumber,
  suspendedPhones: (state) => state.suspendedPhones,

  phoneName: state => state.phoneName,
  forwardType: state => state.forwardType,
  forwardToNumber: state => state.forwardToNumber,
  forwardAs: state => state.forwardAs,
  availableNumbers: state => state.availableNumbers,
  phoneNotificationCount: state => state.phoneNotificationCount,

  virtualPhones: state => state.virtualPhones,
  virtualPhonesToConfigure: state => state.virtualPhonesToConfigure,
  phoneServiceTrialEligible: state => state.phoneServiceTrialEligible,
  customVoicemailUrl: state => state.customVoicemailUrl,
  virtualPhoneById: state => id => state.virtualPhones.find(p => p.id === id),
  virtualPhoneVoicemails: state => state.voicemailsForSelectedPhone,
  voicemailLifespanInDays: state => state.voicemailLifespanInDays,
  virtualPhoneCnamParams: state => state.virtualPhoneCnamParams,
  ringCount: state => state.ringCount,
  greetingType: state => state.greetingType,
  voicemailDuration: state => state.voicemailDuration,
  voicemailById: state => id => state.voicemailsForSelectedPhone.find(v => v.id === id),
  virtualPhonesMatchingFilter: state => filter => {
    return state.virtualPhones.filter(p => {
      return !filter || filterMatch(p.name, filter) || filterMatch(p.company_name, filter)
    })
  },

  // Get the first virtual phone on the account that hasn't been set up in Bandwidth yet.
  phoneToConfigure: (state, getters) => {
    const newPhones = getters.virtualPhones.filter(item => !item.number )
    return (newPhones && newPhones.length > 0) ? newPhones[0] : null
  },
  phoneServiceTrial: (_state, getters) => () => {
    return getters.phoneServiceTrialEligible
  },
  ringtoneOptions: state => state.ringtoneOptions,
  ringtoneExample: state => state.ringtoneExample,
  blockedNumbers: state => state.blockedNumbers,
}

const actions = {
  async loadVirtualPhones({ commit, dispatch }) {
    commit(t.LOAD_VIRTUAL_PHONES_START)

    const response = await http.get('client/virtual_phones', {
      params: { limit: 100, include_count: true, order_by: 'name', order_direction: 'asc' },
    })

    const phones = response.data.result

    if (!state.selectedPhoneId && phones && phones.length > 0) {
      const defaultSelection = phones[0].id
      commit(t.SET_SELECTED_PHONE_ID, { id: defaultSelection })
    }

    commit(t.LOAD_VIRTUAL_PHONES_FINISHED, phones)

    dispatch('setVirtualPhonesToConfigure', phones)
  },

  async loadSuspendedPhones({ commit }) {
    commit(t.LOAD_SUSPENDED_PHONES_START)

    const response = await http.get('client/virtual_phones/suspended-phones', {
      params: { limit: 100, include_count: true, order_by: 'name', order_direction: 'asc' },
    })

    const suspendedPhones = response.data.result

    commit(t.LOAD_SUSPENDED_PHONES_FINISHED, suspendedPhones)
  },

  async setVirtualPhonesToConfigure({ commit, getters }, phones) {
    let virtualPhonesToConfigure = ''
    if (phones){
      virtualPhonesToConfigure = phones.filter(item => !item.number )
    } else if (getters.selectedAvailableNumber && getters.phoneName){
      virtualPhonesToConfigure = getters.virtualPhonesToConfigure
    } else {
      virtualPhonesToConfigure = getters.virtualPhones.filter(item => !item.number )
    }

    commit(t.SET_VIRTUAL_PHONES_TO_CONFIGURE, virtualPhonesToConfigure)
  },

  async setVirtualPhonesPendingRemoval({ commit }){
    const response = await http.get('client/virtual_phones/virtual-phones-pending-removal')

    commit(t.SET_VIRTUAL_PHONES_PENDING_REMOVAL, response.data.result?.phones_pending_removal || [])
  },

  async loadVoicemailsForSelectedVirtualPhone({ commit, getters, dispatch }) {
    if (!getters.selectedPhone) {
      return
    }

    commit(t.LOAD_VOICEMAILS_START)

    const response = await http.get('client/virtual-phone-voicemails', {
      params: {
        virtual_phone_id: getters.selectedPhone.id,
        limit: 100,
        order_by: 'call_ended_at',
        order_direction: 'desc',
      },
    })

    commit(t.LOAD_VOICEMAILS_FINISHED, response.data.result)
    dispatch('selectNextVoicemail')
  },

  async fetchVoicemailSignedUrl({ state, commit, getters, dispatch }) {
    const result = await http.get(`/client/virtual-phone-voicemails/${state.selectedVoicemailId}/download`)
    const temporaryS3Url = result.data.response.url
    getters.selectedVoicemail.status = 'played'

    commit(t.SET_SELECTED_VOICEMAIL_SIGNED_URL, temporaryS3Url)
    dispatch('fetchPhoneNotificationCount')
  },

  async determinePhoneServiceTrialEligibility({ commit }, company_id) {
    const result = await http.get(`/client/trial_eligibility/${company_id}/virtual-phone`)
    const is_eligible = result.data.response.is_eligible

    commit(t.SET_TRIAL_ELIGIBILITY, is_eligible)
  },

  async deleteSelectedVoicemail({ dispatch, state }, vm) {
    try {
      const idToDelete = state.selectedVoicemailId
      const path = `/client/virtual-phone-voicemails/${idToDelete}/mark-deleted`
      await http.get(path)

      dispatch('selectNextVoicemail')
      dispatch('discardVoicemailById', idToDelete)

      vm.$bvToast.toast('Voicemail deleted', {
        title: 'Success',
        variant: 'success',
        solid: true,
        autoHideDelay: 1000,
      })
    } catch (exception) {
      vm.$bvToast.toast('Failed to delete voicemail', {
        title: 'Error',
        variant: 'danger',
        solid: true,
        autoHideDelay: 3000,
      })
    }
  },

  async markSelectedVoicemailDownloaded({ state }) {
    const path = `/client/virtual-phone-voicemails/${state.selectedVoicemailId}/mark-downloaded`
    await http.get(path)
  },

  async setVirtualPhoneCnamParams({ commit }, params) {
    commit(t.SET_VIRTUAL_PHONE_CNAM_PARAMS, params)
  },

  async discardVoicemailById({ commit, state }, id) {
    const newList = state.voicemailsForSelectedPhone.filter(item => item.id !== id)
    commit(t.LOAD_VOICEMAILS_FINISHED, newList)
  },

  async updateVoicemailSettings({ getters, commit }, { updatedParams, errorToast, goodToast }){
    try {
      await http.post(`client/virtual_phones/${getters.selectedPhone.id}/update-voicemail-settings`,
        {
          virtual_phone: {
            voicemail_lifespan_in_days: updatedParams.retentionPeriod,
            greeting_type: updatedParams.greetingType,
            ring_count: updatedParams.ringCount,
            voicemail_duration: updatedParams.voicemailDuration,
          },
        })
      commit(t.SET_GREETING_TYPE, updatedParams.greetingType)
      commit(t.SET_VOICEMAIL_DURATION, updatedParams.voicemailDuration)
      commit(t.SET_VOICEMAIL_LIFESPAN_IN_DAYS, updatedParams.retentionPeriod)
      commit(t.SET_RING_COUNT, updatedParams.ringCount)
      goodToast()
    } catch (error){
      for (const error of Object.values(error.response.data.error.message)) {
        errorToast(error)
      }
    }
  },

  async setSelectedPhoneId({ commit, dispatch, getters }, id) {
    commit(t.SET_SELECTED_PHONE_ID, { id })
    if(getters.virtualPhones === [] || getters.selectedPhone === undefined){
      await dispatch('loadVirtualPhones')
    }
    dispatch('setCustomVoicemailSettings')
  },

  async selectNextVoicemail({ state, dispatch }) {
    const currentIndex = state.voicemailsForSelectedPhone.findIndex(item => item.id === state.selectedVoicemailId)
    const nextIndex = (currentIndex + 1) % state.voicemailsForSelectedPhone.length

    if (state.voicemailsForSelectedPhone[nextIndex]) {
      dispatch('setSelectedVoicemailId', state.voicemailsForSelectedPhone[nextIndex].id)
    }
  },

  async setCustomVoicemailSettings({ commit, getters, dispatch }) {
    if (getters.selectedPhone?.s3_custom_greeting_id !== null){
      await dispatch('fetchCurrentCustomVoicemailUrl')
    }

    commit(t.SET_GREETING_TYPE, getters.selectedPhone.greeting_type)
    commit(t.SET_VOICEMAIL_DURATION, getters.selectedPhone.voicemail_duration)
    commit(t.SET_VOICEMAIL_LIFESPAN_IN_DAYS, getters.selectedPhone.voicemail_lifespan_in_days)
  },

  async setVoicemailDuration({ commit }, voicemailDuration) {
    commit(t.SET_VOICEMAIL_DURATION, voicemailDuration)
  },

  async setVoicemailLifespanInDays({ commit }, voicemailLifespan) {
    commit(t.SET_VOICEMAIL_LIFESPAN_IN_DAYS, voicemailLifespan)
  },

  async setSelectedVoicemailId({ commit, dispatch, getters }, selectedId) {
    const selectedOrDefault = selectedId || getters.virtualPhoneVoicemails[0].id
    commit(t.SET_SELECTED_VOICEMAIL_ID, { id: selectedOrDefault })
    dispatch('fetchVoicemailSignedUrl')
  },

  async setSelectedAreaCode({ commit, dispatch }, areaCode) {
    commit(t.SET_SELECTED_AREA_CODE, areaCode)
    dispatch('fetchAvailableNumbers')
  },

  async setSelectedVirtualPhoneById({ commit }, id) {
    const virtualPhone = state.virtualPhones.some(vp => vp.id === id)
    commit(t.SET_SELECTED_VIRTUAL_PHONE, virtualPhone)
  },

  async fetchCurrentCustomVoicemailUrl({ commit, getters }){
    const response = await http.get(`client/virtual_phones/${getters.selectedPhone.id}/current-custom-voicemail`, {
      params: {
        virtual_phone_id: getters.selectedPhone.id,
        s3_object_id: getters.selectedPhone.s3_custom_greeting_id,
      },
    })

    commit(t.SET_CUSTOM_VOICEMAIL_URL, response.data.response.url)
  },

  async fetchAvailableNumbers({ commit, getters }) {
    const result = await http.post(`/client/bandwidth-numbers/available-numbers`, {
      area_code: getters.selectedAreaCode.code,
      virtual_phone_id: getters.selectedPhone.id,
    })
    commit(t.LOAD_AVAILABLE_NUMBERS_FINISHED, result.data.response)
  },

  async setSelectedAvailableNumber({ commit }, number) {
    commit(t.SET_SELECTED_AVAILABLE_NUMBER, number)
  },

  async triggerConfigModalIfNeeded({ getters }, vm) {
    if (getters.virtualPhonesToConfigure.length > 0){
      vm.$bvModal.show('virtual-phone-setup-modal-step-0')
    }
  },

  async triggerSuspensionNotificationModalIfNeeded({ getters }, vm) {
    if (getters.suspendedPhones.length > 0){
      vm.$bvModal.show('virtual-phone-suspension-notification-modal')
    }
  },

  async setPhoneName({ commit }, { name, vm }) {
    try {
      await http.post(`client/virtual_phones/${getters.selectedPhone.id}/update-phone-settings`,
        {
          name: name,
        }
      )
    } catch (exception) {
      vm.$bvToast.toast('An error occurred saving your nickname.', {
        title: 'Error',
        variant: 'danger',
        solid: true,
        autoHideDelay: 3000,
      })
    }

    commit(t.SET_PHONE_NAME, name)
  },

  async setMobileAccessFinished({ commit }, value) {
    commit(t.SET_MOBILE_ACCESS_FINISHED, value)
  },

  async setForwardType({ commit }, selection) {
    commit(t.SET_FORWARD_TYPE, selection)
  },

  async setForwardToNumber({ commit }, number) {
    commit(t.SET_FORWARD_TO_NUMBER, number)
  },

  async setForwardAs({ commit }, number) {
    commit(t.SET_FORWARD_AS, number)
  },

  async setRingCount({ commit }, ringCount) {
    commit(t.SET_RING_COUNT, ringCount)
  },

  async setPhoneSettings({ getters, dispatch }, { phoneSettings, vm }) {
    try {
      await http.post(`client/virtual_phones/${getters.selectedPhone.id}/update-phone-settings`,
        {
          virtual_phone_id: getters.selectedPhone.id,
          forward_type: phoneSettings.forward_type,
          forward_to_number: phoneSettings.forward_to_number,
          name: phoneSettings.name,
          ringtone: phoneSettings.ringtone,
          media_lifespan_in_days: phoneSettings.media_lifespan_in_days,
        })

        vm.$bvToast.toast('Settings have been saved', {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 2000,
        })

        dispatch('setForwardToNumber', phoneSettings.forward_to_number)
        dispatch('setForwardType', phoneSettings.forward_type)
        dispatch('setPhoneName', phoneSettings.name)
    } catch (exception) {
      vm.$bvToast.toast('We are having troubles at the moment. Please try again at another time.', {
        title: 'Error',
        variant: 'danger',
        solid: true,
        autoHideDelay: 3000,
      })
    }
  },

  async setCallerIdSettings({ getters, dispatch }, { callerIdSettings, vm }) {
    try {
      const result = await http.post(`client/virtual_phones/${getters.selectedPhone.id}/set-cnam-settings`,
        {
          virtual_phone_id: getters.selectedPhone.id,
          number_requested: callerIdSettings.number,
          list_address: callerIdSettings.list_address,
          displayed_first_name: callerIdSettings.displayed_first_name,
          displayed_last_name: callerIdSettings.displayed_last_name,
          house_number: callerIdSettings.house_number,
          street_name: callerIdSettings.street_name,
          street_suffix: callerIdSettings.street_suffix,
          city: callerIdSettings.city,
          state_code: callerIdSettings.state_code,
          zip: callerIdSettings.zip,
        })

      if (result.data.response.status > 204) {
        throw 'An error occurred updating caller id settings'
      }

      vm.$bvToast.toast('Settings have been saved! Please note that the address verification will take 24-48 hours to complete', {
        title: 'Success',
        autoHideDelay: 3000,
      })

      dispatch('setVirtualPhoneCnamParams', result.data.response.data)
    } catch (exception) {
      vm.$bvToast.toast('We are having troubles at the moment. Please try again at another time.', {
        title: 'Error',
        variant: 'danger',
        solid: true,
        autoHideDelay: 3000,
      })
    }
  },

  setTrialEligibility({ commit }, eligible) {
    commit(t.SET_TRIAL_ELIGIBILITY, eligible)
  },

  async submitModalDecisions ({ commit, state, getters, dispatch }) {
    commit(t.MODAL_SUBMISSION_IN_PROGRESS, true)
    let phones = getters.virtualPhones
    const phone = getters.phoneToConfigure

    const response = await http.post(`/client/bandwidth-numbers/purchase-number`, {
      virtual_phone_id: phone.id,
      number: state.selectedAvailableNumber,
      forward_to_number: state.forwardToNumber,
      forward_as: state.forwardAs,
      forward_type: state.forwardType,
    })

    if(response.data.status > 200){
      throw 'Virtual phone could not be found'
    }

    const index = phones.indexOf(getters.virtualPhones.find(p => p.id === phone.id))
    phones.splice(index, 1)
    dispatch('setVirtualPhonesToConfigure', phones)
    dispatch('loadVirtualPhones', phones)
    commit(t.SET_SELECTED_PHONE_ID, phone.id)
    commit(t.MODAL_SUBMISSION_FINISHED, true)
    commit(t.MODAL_SUBMISSION_IN_PROGRESS, false)
  },

  async resetModalFields ({ commit }) {
    commit(t.RESET_MODAL_FIELDS)
  },

  async resetPhoneFields ({ commit }) {
    commit(t.RESET_PHONE_FIELDS)
  },

  async fetchPhoneNotificationCount({ commit }) {
    const result = await http.get(`client/virtual_phones/received-phone-notification-count`)
    commit(t.SET_PHONE_NOTIFICATION_COUNT, result.data.result.count)
  },

  async fetchRingtoneOptions({ commit }) {
    const result = await http.get(`client/virtual_phones/ringtone_options`)

    commit(t.SET_RINGTONE_OPTIONS, result.data.result)
  },

  async fetchRingtone({ commit }, ringtone ) {
    const result = await http.get(`client/virtual_phones/ringtone_example/${ringtone}`)

    commit(t.SET_RINGTONE_EXAMPLE, result.data.result.ringtone)
  },

  async blockNumber({ state, getters }, number) {
    const response = await http.post(`client/virtual_phones/${getters.selectedPhone.id}/add-blocked-number`, {
      blocked_number: number,
    })

    state.blockedNumbers.push(response.data.result.blocked_number)
  },

  async deleteCallLog({ state, commit }, id) {
    await http.delete(`client/virtual_phone_call_logs/delete_call_log/${id}`)
    commit(t.SET_CALL_LOGS, state.callLogs.filter(obj => obj.initial_call_id !== id))
  },

  async getBlockedNumbers({ commit, getters }) {
    const response = await http.get(`client/virtual_phones/${getters.selectedPhone.id}/get-blocked-numbers`)

    commit(t.SET_BLOCKED_NUMBERS, response.data.result.numbers)
  },

  async unblockNumber({ state, commit }, blockedNumberId) {
    await http.post('client/virtual_phones/remove-blocked-number', {
      blocked_number_id: blockedNumberId,
    })

    commit(t.SET_BLOCKED_NUMBERS, state.blockedNumbers.filter(bn => bn.id !== blockedNumberId))
  },
  async commitCallLogs({ commit }, data) {
    const response = await http.get('client/virtual_phone_call_logs', {
      params: {
        virtual_phone_id: data.virtualPhoneId,
        start_date: data.start_date,
        end_date: data.end_date,
      },
    })

    commit(t.SET_CALL_LOGS, response.data.result)
  },
}

const mutations = {
  [t.SET_CALL_LOGS] (state, callLogs) {
    state.callLogs = callLogs
  },
  [t.SET_SELECTED_VIRTUAL_PHONE] (state, virtualPhone) {
    state.selectedVirtualPhone = virtualPhone
  },
  [t.LOAD_VIRTUAL_PHONES_START] (state) {
    state.virtualPhonesFinished = false
    state.voicemailsFinished = false
    state.voicemailsSignedUrlFinished = false
  },

  [t.LOAD_VIRTUAL_PHONES_FINISHED] (state, virtualPhones) {
    state.virtualPhones = sortBy(virtualPhones, 'name')
    state.virtualPhonesFinished = true
  },

  [t.LOAD_SUSPENDED_PHONES_START] (state) {
    state.suspendedPhonesFinished = false
  },

  [t.LOAD_SUSPENDED_PHONES_FINISHED] (state, suspendedPhones) {
    state.suspendedPhones = sortBy(suspendedPhones, 'name')
    state.suspendedPhonesFinished = true
  },

  [t.LOAD_VOICEMAILS_START] (state) {
    state.voicemailsFinished = false
  },

  [t.LOAD_VOICEMAILS_FINISHED] (state, voicemails) {
    state.voicemailsForSelectedPhone = voicemails
    state.voicemailsFinished = true
  },

  [t.SET_VIRTUAL_PHONES_PENDING_REMOVAL] (state, virtualPhonesPendingRemoval) {
    state.virtualPhonesPendingRemoval = virtualPhonesPendingRemoval
  },

  [t.SET_SELECTED_PHONE_ID] (state, { id }) {
    state.selectedPhoneId = id
    state.voicemailsSignedUrlFinished = false
  },

  [t.SET_VIRTUAL_PHONE_ID] (state, id) {
    state.selectedPhoneId = id
  },

  [t.SET_SELECTED_VOICEMAIL_ID] (state, { id }) {
    state.selectedVoicemailId = id
    state.voicemailsSignedUrlFinished = false
  },

  [t.SET_SELECTED_VOICEMAIL_SIGNED_URL] (state, url) {
    state.selectedVoicemailSignedMediaUrl = url
    state.voicemailsSignedUrlFinished = true
  },

  [t.SET_TRIAL_ELIGIBILITY] (state, eligible) {
    state.phoneServiceTrialEligible = eligible
  },

  [t.SET_CUSTOM_VOICEMAIL_URL] (state, customUrl) {
    state.customVoicemailUrl = customUrl
  },

  [t.SET_GREETING_TYPE] (state, greetingType) {
    state.greetingType = greetingType
  },

  [t.SET_VOICEMAIL_DURATION] (state, voicemailDuration) {
    state.voicemailDuration = voicemailDuration
  },

  [t.SET_RING_COUNT] (state, ringCount) {
    state.ringCount = ringCount
  },

  [t.SET_VOICEMAIL_LIFESPAN_IN_DAYS] (state, voicemailLifespanInDays) {
    state.voicemailLifespanInDays = voicemailLifespanInDays
  },

  [t.SET_VIRTUAL_PHONES_TO_CONFIGURE] (state, virtualPhonesToConfigure) {
    state.virtualPhonesToConfigure = virtualPhonesToConfigure
  },

  [t.SET_VIRTUAL_PHONE_CNAM_PARAMS] (state, virtualPhoneCnamParams) {
    state.virtualPhoneCnamParams = virtualPhoneCnamParams
  },

  [t.SET_SELECTED_AREA_CODE] (state, areaCode) {
    state.availableNumbersFinished = false
    state.selectedAreaCode = areaCode
  },

  [t.LOAD_AVAILABLE_NUMBERS_FINISHED] (state, numbers) {
    state.availableNumbers = numbers
    state.availableNumbersFinished = true
  },

  [t.SET_SELECTED_AVAILABLE_NUMBER] (state, number) {
    state.selectedAvailableNumber = number
  },

  [t.SET_PHONE_NAME] (state, name) {
    state.phoneName = name
  },

  [t.SET_FORWARD_TYPE] (state, selection) {
    state.forwardType = selection
  },

  [t.SET_FORWARD_TO_NUMBER] (state, selection) {
    state.forwardToNumber = selection
  },

  [t.SET_FORWARD_AS] (state, selection) {
    state.forwardAs = selection
  },

  [t.SET_MOBILE_ACCESS_FINISHED] (state, value) {
    state.mobileAccessFinished = value
  },

  [t.MODAL_SUBMISSION_FINISHED] (state, selection) {
    state.modalSubmissionFinished = selection
  },

  [t.MODAL_SUBMISSION_IN_PROGRESS] (state, selection) {
    state.modalSubmissionInProgress = selection
  },

  [t.RESET_MODAL_FIELDS] (state) {
    Object.assign(state, { ...state, ...modalFields })
  },

  [t.RESET_PHONE_FIELDS] (state) {
    Object.assign(state, { ...state, ...phoneFields })
  },
  [t.SET_PHONE_NOTIFICATION_COUNT](state, count) {
    state.phoneNotificationCount = count
  },
  [t.SET_RINGTONE_OPTIONS](state, options) {
    state.ringtoneOptions = options
  },
  [t.SET_RINGTONE_EXAMPLE](state, ringtoneExample) {
    state.ringtoneExample = ringtoneExample
  },
  [t.SET_BLOCKED_NUMBERS] (state, blockedNumbers) {
    state.blockedNumbers = blockedNumbers
  },
  [t.SET_SUSPENDED_PHONES] (state, suspendedPhones) {
    state.suspendedPhones = suspendedPhones
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
